import React from "react"

import checkmark from '../components/assets/images/checkmark.png'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Formular from "../components/formular"
import Preise from "../components/preise"
import preiseImage from "../components/assets/images/preise/Schoeffel_DankeMomente_Preise.jpg"
import topImage from "../components/assets/images/header/top-img.png"
import Collage from "../components/assets/images/modulCollage.jpg"
import womenCollectionImage from "../components/assets/images/collection/DAMEN_Schoeffel_LP_DankeMomente_ProduktTeaser_700px1.jpg"
import menCollectionImage from "../components/assets/images/collection/HERREN_Schoeffel_LP_DankeMomente_ProduktTeaser_700px2.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <div className="top-area">
      <img src={topImage} className="top-image" alt="Header"/>
    </div>
    <div className="content-area">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2>
              Schöffel sagt Danke an die Helfer der Corona-Pandemie
            </h2>
            <p>Jetzt ist es Zeit Danke zu sagen! Deshalb schenkt Schöffel allen Helfern der Corona-Pandemie
              einzigartige Danke Momente.
              Wenn Du zu den Menschen gehörst, die sich in irgendeiner Art und Weise in den vergangenen Wochen für
              andere Mitbürger engagiert haben,
              schenken wir Dir unvergessliche Momente – von exklusiven Treffen mit unseren ÖSV-Ski-Stars bis zu
              traumhaften
              Rundum-Sorglos-Tagen mit Bergführer in den Alpen.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="schoeffel-video-box">
              <iframe width="100%" src="https://www.youtube.com/embed/oOUfQUblr1U?autoplay=0&rel=0" frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen className="schoeffel-video"></iframe>
            </div>
          </div>
          <div className="col-sm-12 col-md-8 pt-2 pt-md-0 pl-md-2">
            <h2 className="mt-md-0">
              So einfach geht‘s
            </h2>
            <div className="flex-container-horizontal">
              <img src={checkmark} className="img-checkmark" alt="checkmark"/>
              <p className="font-weight-bold check-text">Wähle Deinen individuellen, traumhaften Schöffel Danke Moment in unserer Momente-Übersicht</p>
            </div>
            <div className="flex-container-horizontal">
              <img src={checkmark} className="img-checkmark" alt="checkmark"/>
              <p className="font-weight-bold check-text">Fülle kostenlos und unverbindlich das Bewerbungs-Formular aus</p>
            </div>
            <div className="flex-container-horizontal">
              <img src={checkmark} className="img-checkmark" alt="checkmark"/>
              <p className="font-weight-bold check-text">Genieße Deinen ganz persönlichen Schöffel Danke Moment</p>
            </div>
            <a href="#form"><p><b>Jetzt direkt bewerben</b></p></a>
          </div>
        </div>
        <div className="row">
          <div className="centered-content">
            <img src={preiseImage} className="img-responsive" alt="Preise Überblick"/>
          </div>
        </div>
        <Preise/>
        <Formular/>
        <div className="row pt-3">
          <div className="centered-content">
            <h2>
              Nachhaltig performen.<br/>
              Die Schöffel Trek & Hike Kollektion.
            </h2>
          </div>
        </div>
        <div>
          <div className="centered-content">
            <a href="https://www.schoeffel.com/de/de/damen"><img src={womenCollectionImage} className="img-responsive pb-2" alt="Damenkollektion"/></a>
            <a href="https://www.schoeffel.com/de/de/herren"><img src={menCollectionImage} className="img-responsive pb-2" alt="Herrenkollektion"/></a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
