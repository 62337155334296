import React from "react"

//Preisbilder
import preis1Image from "./assets/images/preise/1_Benni_Raich.jpg"
import preis2Image from "./assets/images/preise/2_Preis_Jacke.jpg"
import preis3Image from "./assets/images/preise/3_Schoeffel_LP_DankeMomente_Preise_960px4.jpg"
import preis4Image from "./assets/images/preise/4_Schoeffel_LP_DankeMomente_Preise_960px5.jpg"
import preis5Image from "./assets/images/preise/5_Schoeffel_LP_DankeMomente_Preise_960px6.jpg"
import preis6Image from "./assets/images/preise/6_Schoeffel_LP_DankeMomente_Preise_960px7.jpg"
import preis7Image from "./assets/images/preise/1_GEPA_full_4321_GEPA-04052099619.jpg"
import preis8Image from "./assets/images/preise/8_Schoeffel_Titlis_Rotair.jpg"
import preis9Image from "./assets/images/preise/9_Schoeffel_LP_DankeMomente_Preise_960px9.jpg"
import preis10Image from "./assets/images/preise/10_Schilthorn_Drohne_1804_2_webalbum.jpg"
import preis11Image from "./assets/images/preise/11_GEPA_full_9615_GEPA-07011750152.jpg"
import preis12Image from "./assets/images/preise/12_Schoeffel_LP_DankeMomente_Preise_960px10.jpg"
import preis13Image from "./assets/images/preise/13_NEUFUNDLAND_1_JACKET_5890_DAMEN_0145_72i.jpg"
import preis14Image from "./assets/images/preise/14_Co_Pilot_am_Hochfelln.jpg"
import preis15Image from "./assets/images/preise/7_Schoeffel_LP_DankeMomente_Preise_960px8.jpg"
import preis16Image from "./assets/images/preise/16_Img_Saalback.jpg"

//Preislogos
import preis4logo from "./assets/images/preise/logos/4_Logo_St.Anton.jpg"
import preis5logo from "./assets/images/preise/logos/5_Logo_Lowa.jpg"
import preis7logo from "./assets/images/preise/logos/7_ast.oas_cmyk-deutsch.jpg"
import preis8logo from "./assets/images/preise/logos/8_TITLIS_Engelberg_Logo_CMYK.jpg"
import preis9logo from "./assets/images/preise/logos/9_LEKI Logo.png"
import preis10logo from "./assets/images/preise/logos/10_Schilthorn_logo_schnitt.jpg"
import preis11logo from "./assets/images/preise/logos/11_Logo_Chuenis_Schwarz.jpg"
import preis12logo from "./assets/images/preise/logos/12_400px-Bergwelten.png"
import preis14logo from "./assets/images/preise/logos/14_seilbahn-logo-neu.jpg"
import preis15logo from "./assets/images/preise/logos/15_Logo_SwissSki.jpg"
import preis16logo from "./assets/images/preise/logos/16_Logo_Saalbach.jpg"

const preisData = {
  0: {
    title: "@bold@Schöffel Danke Moment #1:@bold@ Skitag mit Benni Raich in seiner Heimat Pitztal",
    text: "Mit einem unvergesslichen @bold@Ich bin raus@bold@-Skitag möchte sich einer der erfolgreichsten Skirennläufer aller Zeiten bei Dir für deinen Einsatz für das Wohle unser aller bedanken. Benni wird Dir persönlich sein Heimatskigebiet zeigen und bei Bedarf auch an Deiner Performance am Ski feilen. Mit Spaß, Tipps & Tricks von einem echten Profi wird es bestimmt ein Erlebnis, das Du so schnell nicht vergessen wirst.",
    image: preis1Image,
    link: "https://benjaminraich.at/"
  },
  1: {
    title: "@bold@Schöffel Danke Moment #2:@bold@ Ich bin raus-Momente in Schöffel Fleece Hoody mit persönlicher Widmung genießen",
    text: "Wir schenken Dir @bold@eine@bold@ von @bold@150 limitierten Schöffel Fleece Hoodys@bold@. Die Kollegen aus der @bold@Schöffel Service Factory@bold@ besticken den stylischen Hoody @bold@individuell@bold@ mit Deinem Namen – für fantastische \n@bold@Ich bin raus@bold@-Momente in Deiner persönlichen Helden-Jacke.",
    image: preis2Image
  },
  2: {
    title: "@bold@Schöffel Danke Moment #3:@bold@ Genialer Single Trail Tag mit Roman Rohrmoser",
    text: "@bold@Freeride-Star@bold@ und Schöffel Markenbotschafter @bold@Roman Rohrmoser@bold@ lädt Dich zu einem gemeinsamen @bold@Singletrail Tag@bold@ im @bold@Zillertal@bold@ ein. Roman zeigt Dir seine @bold@Lieblingsstrecken@bold@ und nimmt Dich mit auf die traumhaften Abfahrten der @bold@Tiroler Alpen@bold@ – ein exklusiver Berg-Genießer Moment.",
    image: preis3Image,
    link: "https://www.roman-rohrmoser.com"
  },
  3: {
    title: "@bold@Schöffel Danke Moment #4:@bold@ Wellness und Berg-Vergnügen in St. Anton",
    text: "Wir laden Dich + Begleitung zu einem @bold@3-Tagesaufenthalt@bold@ in ein wunderschönes @bold@4****@bold@ Hotel @bold@nach St. Anton@bold@ am @bold@Arlberg@bold@ ein. Genieße die Natur in den @bold@Tiroler Alpen@bold@, lass den Abend bei einem Glas Wein und Schmankerln ausklingen und genieße den Moment beim Relaxen.",
    image: preis4Image,
    logo: preis4logo,
    link: "https://www.stantonamarlberg.com/de"
  },
  4: {
    title: "@bold@Schöffel Danke Moment #5:@bold@ Traumhafte Wanderungen in LOWA All Terrain Schuhen",
    text: "Für sorgenfreie, entspannte @bold@Ich bin raus@bold@-Momente ist gutes Schuhwerk, neben funktioneller Bekleidung, das Wichtigste. Wir schenken Dir passende LOWA All Terrain Schuhe, die Du auf keiner Wanderung mehr missen möchtest.",
    image: preis5Image,
    logo: preis5logo,
    link: "https://www.lowa.de"
  },
  5: {
    title: "@bold@Schöffel Danke Moment #6:@bold@ Outdoor-Tag mit Bergsteiger-Legende Ralf Dujmovits",
    text: "Diesen Outdoor Tag mit @bold@Ralf Dujmovits@bold@, der schon alle 14 Achttausender bestiegen hat, wirst Du so schnell nicht vergessen. Ralf nimmt Dich + Begleitung mit auf einen gemeinsamen, traumhaften @bold@Ausflug@bold@. Mit dem Karlsruher Grat im Schwarzwald zeigt Dir Ralf seine absolute Lieblingstour in seiner Heimat – mit vielen beeindruckenden Momenten und wunderbaren Geschichten.",
    image: preis6Image,
    link: "https://www.ralfdujmovits.de"
  },
  6: {
    title: "@bold@Schöffel Danke Moment #7:@bold@ Unvergessliches Meet & Greet mit den ÖSV Ski-Stars",
    text: "Wir laden Dich + Begleitung zur @bold@Schöffel ÖSV Einkleidungspräsentation@bold@ als @bold@VIP Gast@bold@ ein. Lerne Marco Schwarz, Nici Schmidhofer und den kompletten @bold@Ski Austria Alpin Kader@bold@ am 09. Oktober 2020 in Salzburg persönlich kennen, knipse geniale Selfies mit Deinen Skistars und lass Dich vom traumhaften VIP-Service verwöhnen.",
    image: preis7Image,
    logo: preis7logo,
    link: "https://www.oesv.at"
  },
  7: {
    title: "@bold@Schöffel Danke Moment #8:@bold@ Ski-Spaß für 4 Personen im Skigebiet Zentralschweiz",
    text: "Wir schenken Dir einen sensationellen @bold@Pisten-Tag@bold@ in der @bold@Zentralschweiz@bold@. Mit einem @bold@4-Personen-Skipass-Paket@bold@ kannst Du mit Deinen liebsten die Seele im frischen Powder baumeln lassen und @bold@das Skigebiet Engelberg-TILTIS@bold@ für einen Tag lang genießen. Für einen genialen Skimoment zum Start in die nächste Saison.",
    image: preis8Image,
    logo: preis8logo,
    link: "https://www.titlis.ch"
  },
  8: {
    title: "@bold@Schöffel Danke Moment #9:@bold@ Sichere Bergmomente mit LEKI Trekking-Stöcken",
    text: "Für angenehmere @bold@Ich bin raus@bold@-Momente schenken wir Dir Highend-Trekkingstöcke von LEKI für höchste technische und ästhetische Ansprüche. Die leichten, faltbaren Carbon-Stöcke sind eine echte Entlastung bei jedem Auf- und Abstieg.",
    image: preis9Image,
    logo: preis9logo,
    link: "https://www.leki.com"
  },
  9: {
    title: "@bold@Schöffel Danke Moment #10:@bold@ Rundum-Sorglos-Tag auf dem Schweizer Schilthorn",
    text: "Wir laden Dich zu einer @bold@Tour@bold@ ins @bold@Schilthorngebiet@bold@ ein. Der einheimische Bergführer Toni kennt die Region wie seine Westentasche. Er nimmt Dich mit in die @bold@atemberaubende Bergwelt@bold@ und zeigt Dir einen seiner Geheimtipps der Region. Je nach Tourenplanung gibt es einen reichhaltigen Brunch auf dem Schilthorn oder ein bergiges Mittagessen in einem der bezaubernden Restaurants.",
    image: preis10Image,
    logo: preis10logo,
    link: "https://schilthorn.ch"
  },
  10: {
    title: "@bold@Schöffel Danke Moment #11:@bold@ 5 Tickets für den Slalom Weltcup 2021 in Adelboden",
    text: "Wenn es am @bold@10. Januar 2021@bold@ in @bold@Adelboden@bold@ beim @bold@FIS Ski Weltcup@bold@ im Slalom-Wettbewerb heiß hergeht, bist Du mit Deinen Liebsten dabei – wir laden Dich zum Ski-Rennen im @bold@Berner Oberland@bold@ ein und schenken Dir @bold@5 Tickets@bold@ für das beliebte Wintersport-Event.",
    image: preis11Image,
    logo: preis11logo,
    link: "https://www.weltcup-adelboden.ch/"
  },
  11: {
    title: "@bold@Schöffel Danke Moment #12:@bold@ Jahresabo BERGWELTEN Magazin + Hängematte",
    text: "Vorfreude ist die schönste Freude – und damit Du mit jeder Menge Begeisterung Deine nächsten @bold@Ich bin raus@bold@-Momente planen kannst, schenken wir Dir ein Jahresabo für das BERGWELTEN Magazin + gemütlicher Hängematte zum Relaxen und lesen.",
    image: preis12Image,
    logo: preis12logo,
    link: "https://www.bergwelten.com/"
  },
  12: {
    title: "@bold@Schöffel Danke Moment #13:@bold@ Wanderung mit Bergsteiger-Königin Gerlinde Kaltenbrunner",
    text: "Einen unvergesslichen Tag kannst Du + Begleitung mit @bold@Gerlinde Kaltenbrunner@bold@ verbringen. Gerlinde war als erste Frau auf allen 14 Achttausendern ohne Sauerstoff und ist deshalb die erfolgreichste Bergsteigerin aller Zeiten. Gemeinsam mit Gerlinde erlebst Du einen @bold@traumhaften Tag in der Natur@bold@ und vielen Tipps und Tricks für das perfekte @bold@Ich bin raus@bold@-Erlebnis.",
    image: preis13Image,
    link: "http://www.gerlinde-kaltenbrunner.at/de/"
  },
  13: {
    title: "@bold@Schöffel Danke Moment #14:@bold@ Mitfahrt in der Pistenraupe der Hochfelln Seilbahnen",
    text: "Wenn es auf dem @bold@oberbayerischen Hochfelln@bold@ wieder in Sachen Skifahren rund geht, laden wir Dich zu einer exklusiven @bold@Mitfahrt in der Pistenraupe@bold@ ein. Wenn Du schon immer einmal wissen wolltest, wie es sich anfühlt, in einer Pistenraupe zu sitzen und die steilen Hänge des Hochfelln zu präparieren, hast Du jetzt hier die Möglichkeit! Genieße das abendliche Lichtermeer des @bold@Chiemgaus@bold@ und die Ruhe auf dem Hochfelln.",
    image: preis14Image,
    logo: preis14logo,
    link: "https://www.hochfelln-seilbahnen.de/startseite/"
  },
  14: {
    title: "@bold@Schöffel Danke Moment #15:@bold@ Die nächste Reise mit exklusiver Swiss Ski Team Tasche",
    text: "Diese schicke @bold@Tasche@bold@ haben sonst nur die besten Ski Cross Fahrer der Welt. Wir schenken Dir eine exklusive @bold@Swiss Ski Team Tasche@bold@ mit @bold@Team-Kulturbeutel@bold@ – damit Du auf Deiner nächsten Abenteuerreise bestens ausgerüstet bist.",
    image: preis15Image,
    logo: preis15logo,
    link: "https://www.swiss-ski.ch/ueber-swiss-ski/mitglieder-breitensport/mitglieder-bereich/mitglied-werden/"
  },
  15: {
    title: "@bold@Schöffel Danke Moment #16:@bold@ Genieße ein Wochenende (Freitag - Sonntag) für 2 Personen in Saalbach Hinterglemm Leogang Fieberbrunn",
    text: "Mit unzähligen Kilometern an Wanderwegen und gut ausgebauten Klettersteigen in verschiedenen Schwierigkeitsstufen punktet die Region bei Gipfelstürmern. Bike-Fans gehen ebenfalls nicht leer aus und können sich auf über 70 km Trails & Trails und 9 Bergbahnen auf 7 Bergen in Österreichs größter Bikeregion austoben. Auf Familien warten zahlreiche Spielplatze, Abenteuer- und Erlebniswege, Lehrpfade und Vieles mehr.",
    image: preis16Image,
    logo: preis16logo,
    link: "https://www.saalbach.com/"
  }
}
export default class preise extends React.Component {
  state = {
    isCollapsed: [true, true, true, true, true, true, true, true, true, true, true, true, true]
  }

  boldTextVersion = (originalText, id) => {
    const splittedText = originalText.split("@bold@")
    const text = []
    for (let i = 0; i < splittedText.length; i++) {
      if (i % 2 === 0) {
        text.push(<p className="display-inline" key={"text_" + i}>{splittedText[i] + " "}</p>)
      } else {
        const nextChar = splittedText[i + 1].substr(0, 1)
        if ((nextChar === "." || nextChar === "-" || nextChar === ",")) {
          text.push(<p className="display-inline font-weight-bold" key={"text_" + i}>{splittedText[i]}</p>)
        } else {
          text.push(<p className="display-inline font-weight-bold" key={"text_" + i}>{splittedText[i] + " "}</p>)
        }
      }
    }
    return <div className="flow-container-horizontal pb-0" key={id + "_text"}>{text}</div>
  }

  boldTitleVersion = (originalText, id) => {
    const splittedText = originalText.split("@bold@")
    const text = []
    for (let i = 0; i < splittedText.length; i++) {
      if (i % 2 === 0) {
        text.push(<p className="display-inline price-title" key={"text_" + i}>{splittedText[i]}</p>)
      } else {
        const nextChar = splittedText[i + 1].substr(0, 1)
          if ((nextChar === "." || nextChar === "-")) {
            text.push(<h2 className="display-inline" key={"text_" + i}>{splittedText[i]}</h2>)
          } else {
            text.push(<h2 className="display-inline" key={"text_" + i}>{splittedText[i] + " "}</h2>)
          }
      }
    }
    return <div className="flow-container-horizontal pt-3 pt-md-0 pb-2" key={id + "_title"}>{text}</div>
  }

  renderPriceImage = (image, id, link) => {
    if (link) {
      return (<a href={link} target='_blank' rel='noopener noreferrer'><img src={image} className="img-responsive" alt="dummy"
                                                                    key={id + "_image"}/></a>)
    } else {
      return (<img src={image} className="img-responsive" alt="Preis" key={id + "_image"}/>)
    }
  }

  renderPrices = (i) => {
    let id = "id_" + i
    let preisDataText = this.boldTextVersion(preisData[i].text, id)
    let preisDataTitle = this.boldTitleVersion(preisData[i].title, id)
    let logo = <div/>
    if (preisData[i].logo) logo =
      <img src={preisData[i].logo} className="img-logo" alt="Logo" key={id + "_image_logo"}/>
    return (
      <div className="row" key={id + "_row"}>
        <div className="col-sm-12 col-md-4" key={id + "_imageContainer"}>
          {this.renderPriceImage(preisData[i].image, id, preisData[i].link)}
          {logo}
        </div>
        <div className="col-sm-12 col-md-8 pl-md-2" key={id + "_priceContainer"}>
          {preisDataTitle}
          <div className="preis-content" key={id + "_priceTextContainer"}>
            {preisDataText}
            <a href="#form"><p className="mb-2"><b>Jetzt hier für diesen Danke Moment bewerben!</b></p></a>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    let prices = []
    for (let i = 0; i < Object.keys(preisData).length; i++) {
      prices.push(this.renderPrices(i))
    }

    return (
      <div>
        {prices}
      </div>
    )
  }

}
