import React from "react"
import ReCAPTCHA from "react-google-recaptcha";
import SharedConstants from "../constants/SharedConstants"

export default class formular extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    adress: "",
    price: "",
    reason: "",
    size: "",
    street: "",
    disabled: true,
    legal: false,
    showDataHint: false,
    showEmailHint: false,
    showLegalHint: false,
    hasClickedSubmit: false,
    submitted: false
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if (name === "email") {
      this.setState({ showEmailHint: false})
    }
    if (name === "legal") {
      this.setState({ showLegalHint: false})
    }
    this.setState({
      [name]: value,
      showDataHint: false
    })
  }

  validateEmail = (email) => {
    const mailtest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailtest.test(email);
  };

  hasEnteredData = () => {
    this.setState({ hasClickedSubmit: true })
    if (this.state.firstName === "" || this.state.lastName === "" ||
      this.state.email === "" || this.state.phone === "" || this.state.adress === "" ||
      this.state.price === "" || this.state.reason === "" || this.state.size === "" || this.state.street === "") {
      this.setState({ showDataHint: true })
    } else if (!this.state.legal) {
      this.setState({ showLegalHint: true })
    } else if (this.validateEmail(this.state.email)) {
      let user = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        adress: this.state.adress,
        street: this.state.street,
        price: this.state.price,
        size: this.state.size,
        reason: this.state.reason
      }
        fetch('https://europe-west1-schoeffel-gewinnspiel-2020-5.cloudfunctions.net/safeParticipantData', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: user,
            token: "KAFH46jHFAJF857jydfHASGD876DFKAGH8457n457vJFHG746n"
          })
        }).then(r => {
          this.setState({submitted: true});
          setTimeout(() => {
            window.fbq('trackCustom', 'Submitted');
          }, 0);
        }).catch(error => {
          console.log(error);
        })
    } else {
      this.setState({ showEmailHint: true })
    }
  }

  handleCaptchaResponse = () => {
    this.setState({disabled: false})
  }

  render() {
    let dataHint = <div/>;
    if (this.state.showDataHint) {
      dataHint = <div><p className="hint-text">Bitte fülle alle Felder aus.</p></div>
    } else if (this.state.showEmailHint) {
      dataHint = <div><p className="hint-text">Bitte überprüfe die eingegebene E-Mail Adresse.</p></div>
    } else if (!this.state.legal && this.state.hasClickedSubmit) {
      dataHint = <div><p className="hint-text">Bitte aktzeptiere die Teilnahmebedingungen.</p></div>
    }

    if (this.state.submitted) {
      return (
        <div className="row" id="form">
          <div>
            <h2>
              Danke für Deine Teilnahme!
            </h2>
            <p>Wir drücken Dir die Daumen und bedanken uns für Deine Teilnahme - und natürlich auch für Deine Hilfe in der Zeit der Corona-Pandemie.</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" id="form">
          <div className="col-sm-12 col-md-12">
            <h2>
              Jetzt bewerben und tolle Danke-Momente geschenkt bekommen!
            </h2>
            <div>
              <div className="form-group">
                <label htmlFor="inputFirstName">Vorname *</label>
                <input type="text" className="form-control" id="inputFirstName" name="firstName"
                       aria-describedby="emailHelp" placeholder="Dein Vorname" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputLastName">Nachname *</label>
                <input type="text" className="form-control" id="inputLastName" name="lastName"
                       aria-describedby="emailHelp" placeholder="Dein Nachname" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">E-Mail *</label>
                <input type="email" className="form-control" id="inputEmail" name="email"
                       aria-describedby="emailHelp" placeholder="Deine E-Mail Adresse" required
                       onChange={this.handleInputChange}/>
                <small id="emailHelp" className="form-text text-muted">Wir teilen deine
                  E-Mailadresse mit niemandem.</small>
              </div>
              <div className="form-group">
                <label htmlFor="inputPhone">Telefon *</label>
                <input type="tel" className="form-control" id="inputPhone" name="phone"
                       aria-describedby="emailHelp" placeholder="Deine Telefonnummer" required
                       onChange={this.handleInputChange}/>
                <small id="emailHelp" className="form-text text-muted">Wir teilen deine
                  Telefonnummer mit niemandem.</small>
              </div>
              <div className="form-group">
                <label htmlFor="inputAdress">Wohnort *</label>
                <input type="text" className="form-control" id="inputAdress" name="adress"
                       aria-describedby="emailHelp" placeholder="Dein Wohnort" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputStreet">Straße und Hausnummer *</label>
                <input type="text" className="form-control" id="inputStreet" name="street"
                       aria-describedby="emailHelp" placeholder="Deine Straße und Hausnummer" required
                       onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label htmlFor="inputPrice">Preis Auswahl *</label>
                <select className="form-control" id="inputPrice" name="price" onChange={this.handleInputChange}>
                  <option default hidden>Preis wählen...</option>
                  <option>#1: Skitag mit Benni Raich in seiner Heimat Pitztal</option>
                  <option>#2: Bergtouren in Fleece Hoody mit persönlicher Widmung genießen</option>
                  <option>#3: Genialer Single Trail Tag mit Roman Rohrmoser</option>
                  <option>#4: Wellness und Berg-Vergnügen in St. Anton</option>
                  <option>#5: Traumhafte Wanderungen in LOWA All Terrain Schuhen</option>
                  <option>#6: Outdoor-Tag mit Bergsteiger-Legende Ralf Dujmovits</option>
                  <option>#7: Unvergessliches Meet & Greet mit den ÖSV Ski-Stars</option>
                  <option>#8: Ski-Spaß für 4 Personen im Skigebiet Zentralschweiz</option>
                  <option>#9: Sichere Bergmomente mit LEKI Trekking-Stöcken</option>
                  <option>#10: Rundum-Sorglos-Tag auf dem Schweizer Schilthorn</option>
                  <option>#11: 2 x 5 Tickets für den Slalom Weltcup 2021 in Adelboden</option>
                  <option>#12: Jahresabo Bergwelten Magazin + Hängematte</option>
                  <option>#13: Wanderung mit Bergsteiger-Königin Gerline Kaltenbrunner</option>
                  <option>#14: Mitfahrt in der Pistenraupe der Hochfelln Seilbahnen</option>
                  <option>#15: Die nächste Reise mit exklusiver Swiss Ski Team Tasche</option>
                  <option>#16: Wochenende für 2 Personen in Saalbach Hinterglemm Leogang Fieberbrunn</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="inputSize">Konfektionsgröße *</label>
                <select className="form-control" id="inputSize" name="size" onChange={this.handleInputChange}>
                  <option default hidden>Konfektion wählen...</option>
                  <option>32</option>
                  <option>34</option>
                  <option>36</option>
                  <option>38</option>
                  <option>40</option>
                  <option>42</option>
                  <option>44</option>
                  <option>46</option>
                  <option>48</option>
                  <option>50</option>
                  <option>52</option>
                  <option>54</option>
                  <option>56</option>
                  <option>58</option>
                  <option>60</option>
                  <option>62</option>
                  <option>64</option>
                  <option>66</option>
                  <option>68</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="inputReason">Warum möchtest du einen Danke Moment gewinnen? *</label>
                <input type="text" className="form-control" id="inputReason" name="reason"
                       aria-describedby="emailHelp" placeholder="Erzähl es uns..." required
                       onChange={this.handleInputChange}/>
              </div>
              <div>
                <label htmlFor="inputLegal" className="legal-label justify-content-center">
                  <input type="checkbox" id="inputLegal" name="legal"
                         aria-describedby="emailHelp" required
                         onChange={this.handleInputChange} className="legal-checkbox mr-2"/>
                  Die <a href="https://www.schoeffel.com/de/de/datenschutz" className="link-text" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> dieser Website habe ich zur Kenntnis genommen und die <a href="/teilnahmebedingungen/index.html" className="link-text" target="_blank">Teilnahmebedingungen</a> der Aktion akzeptiert.
                </label>
              </div>
              {dataHint}
              <div>
                <ReCAPTCHA
                  sitekey={SharedConstants.RECAPTCHA_SITE_KEY}
                  render={"explicit"}
                  onChange={this.handleCaptchaResponse}
                  className="centered-recap"
                />
              </div>
              <button className="btn btn-primary mt-2" onClick={this.hasEnteredData} disabled={this.state.disabled}>Absenden</button>
            </div>
          </div>
        </div>
      )
    }
  }
}
